<template>
  <b-card no-body>
    <b-table
      :items="items"
      :fields="fields"
      responsive
    >
      <template #cell(folio)="data">
        <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
      </template>
      <template #cell(address)="data">
        <span class="font-weight-bolder">{{ data.item.address.formatted_address }}</span>
      </template>
      <template #cell(published_at)="data">
        <span>{{ data.value | date }}</span>
      </template>
      <template #cell(actions)>
        <div>
          <b-button
            class="mr-1"
            size="sm"
            variant="link"
          >
            <feather-icon
              size="18"
              icon="MoreVerticalIcon"
            />
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  components: {},

  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      fields: [
        {
          key: 'folio',
          label: 'Folio',
        },
        {
          key: 'address',
          label: this.$t('address'),
        },
        {
          key: 'published_at',
          label: this.$t('publishedDate'),
        },
      ],
    }
  },

  computed: {},

  methods: {
    ...mapMutations('suppliers', {
      selectProvider: 'SET_SELECTED_SUPPLIER',
    }),
    ...mapMutations('branches', {
      selectBranch: 'SET_SELECTED_BRANCH',
    }),
  },
}
</script>
