<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="">
    <b-row class="mt-1">
      <b-col
        sm="3"
        class=""
      >
        <div class="bg-white text-center px-2 h-100 border rounded shadow mr-2">
          <div class="d-flex align-items-center justify-content-between h-100">
            <div class="flex-grow-1">
              <h3>{{ product.product_catalog.name }}</h3>
              <div class="d-flex flex-column">
                <span class="text-muted h5">{{ product.serial_number }}</span>
                <div>
                  {{ $t('quantity') }}:
                  {{ product.quantity }}
                  {{ $tc(unitsFilter(product.unit_type), product.quantity) }}
                </div>
              </div>
            </div>
            <div>
              <b-img
                width="70px"
                :src="`${product.product_catalog.logo}`"
              />
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <b-row class="bg-white border rounded shadow">
          <b-col
            sm="5"
            class="px-2 custom-padding"
          >
            <h5>{{ $t('description') }}</h5>
            <div class="text-justify text-muted">
              {{ product.product_catalog.description }}
            </div>
          </b-col>
          <b-col
            sm="7"
            class="d-flex flex-row justify-content-end align-items-start py-1"
          >
            <div class="d-flex flex-column">
              <div class="d-flex flex-column align-items-end">
                <span class="font-weight-bolder">{{ $t('unitPrice') }}</span>
                <span
                  class="font-weight-bolder"
                >S/IVA $ {{ product.unit_price | money }}</span>
              </div>

              <div class="d-flex align-items-center mt-1 justify-content-end">
                <div>
                  <div class="d-flex align-items-center mr-1">
                    <h3 class="m-0 font-weight-bolder mr-1">
                      {{ $t('total') }}:
                    </h3>
                    <span
                      class="font-weight-bolder text-success h3 m-0"
                    >$
                      {{
                        (parseFloat(product.unit_price) *
                          parseFloat(product.quantity))
                          | money
                      }}</span>
                  </div>
                  <div>
                    {{ $t('quantity') }}: {{ product.quantity }}
                    {{ $tc(unitsFilter(product.unit_type), product.quantity) }}
                  </div>
                </div>
                <div>
                  <div v-if="roundStage === 'firstRound'" class="d-flex flex-column">
                    <b-badge
                      v-if="product.supplier_has_bid"
                      variant="success"
                      class="mb-1"
                    >
                      <feather-icon icon="CheckIcon" />
                      Precio mejorado
                    </b-badge>
                    <b-button
                      variant="primary"
                      @click="openImpovePriceModal(product)"
                    >
                      {{ $t('improvePrice') }}
                    </b-button>
                  </div>
                  <b-button
                    v-else-if="roundStage === 'secondRound'"
                    variant="primary"
                  >{{ $t('improvePrice') }}</b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="roundStage == 'secondRound'"
        sm="2"
        class="px-0"
      >
        <div
          class="bg-best-price text-center h-100 d-flex align-items-center justify-content-center border rounded shadow ml-2"
        >
          <div class="d-flex flex-column font-weight-bolder">
            <span class="text-light">
              {{ $t('unitPrice') }}
            </span>
            <span
              class="text-light"
            >S/IVA $ {{ product.unit_price | money }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      :id="`improve-price-modal-${product.id}`"
      :title="$t('improvePrice')"
      :ok-title="$t('improvePrice')"
      :cancel-title="$t('cancel')"
      cancel-variant="outline-danger"
      @ok="bidPrice"
    >
      <h3 class="text-center">
        {{ product.product_catalog.name }}
      </h3>
      <div class="text-center">
        <span class="font-weight-bolder">{{ $t('unitPrice') }}</span>
        <span class="font-weight-bolder">S/IVA $ {{ product.unit_price }}</span>
      </div>
      <div class="text-center">
        {{ $t('quantity') }}: {{ product.quantity }}
        {{ $tc(unitsFilter(product.unit_type), product.quantity) }}
      </div>
      <div class="d-flex justify-content-end align-items-center mr-1">
        <h3 class="m-0 font-weight-bolder mr-1">
          {{ $t('total') }}:
        </h3>
        <span
          class="font-weight-bolder text-success h3 m-0"
        >$
          {{
            (parseFloat(productToImprove.unit_price) *
              parseFloat(product.quantity))
              | money
          }}</span>
      </div>
      <b-form-group :label="$t('improvePrice')">
        <b-form-input v-model="productToImprove.unit_price" autocomplete="off" type="number" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    roundStage: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      productToImprove: {},
    }
  },

  computed: {
    colSize() {
      if (this.roundStage === 'firstRound') return '9'
      if (this.roundStage === 'secondRound') return '7'
      return ''
    },
    ...mapState(['apiUrl']),
    ...mapGetters('app', ['unitsFilter']),
  },

  methods: {
    ...mapActions('quotes', ['bidInQuote', 'fetchSupplierBranchQuoteItems']),

    openImpovePriceModal(product) {
      this.productToImprove = { ...product }
      this.$bvModal.show(`improve-price-modal-${product.id}`)
    },

    bidPrice() {
      if (!this.validateDecimals()) {
        return
      }
      this.bidInQuote({
        quoteId: this.$route.params.quoteId,
        supplier_branch_quote_items_records: [{ ...this.productToImprove }],
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('itemBidded'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.fetchSupplierBranchQuoteItems({
          quoteId: this.$route.params.quoteId,
          branchId: this.$route.query.branchId,
        })
        this.$bvModal.hide(`improve-price-modal-${this.productToImprove.id}`)
      })
    },
    validateDecimals() {
      if (this.productToImprove.unit_price.includes('.') && this.productToImprove.unit_price.split('.')[1].length > 2) {
        this.quantityValid = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo se permiten 2 decimales',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-best-price {
  background-color: #77c77b;
}
.custom-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
