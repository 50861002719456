<template>
  <div class="">
    <b-row class="h-100 mt-1">
      <b-col
        sm="3"
        class="px-0"
      >
        <div class="bg-white text-center p-2 h-100 border rounded shadow mr-2">
          <div class="d-flex align-items-center justify-content-between h-100">
            <div class="flex-grow-1">
              <h3>{{ product.name }}</h3>
              <div class="d-flex flex-column">
                <span class="text-muted h5">{{ product.serial_number }}</span>
                <div>
                  {{ $t('quantity') }}:
                  {{ product.quantity }}
                  {{ $tc(unitsFilter(product.unit_type), product.quantity) }}
                </div>
              </div>
            </div>
            <div>
              <b-img
                width="70px"
                :src="product.image"
              />
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="9">
        <b-row class="h-100 border rounded shadow bg-best-price text-light">
          <b-col
            sm="4"
            class="px-2 py-1"
          >
            <h5 class="text-light">
              {{ $t('description') }}
            </h5>
            <div class="text-justify">
              {{ product.description }}
            </div>
          </b-col>
          <b-col sm="4">
            <div
              class="d-flex flex-column justify-content-center h-100 align-items-center"
            >
              <h3 class="m-0 font-weight-bolder mr-1 text-light">
                {{ $t('uGotBestPrice') }}
              </h3>
              <b-button
                class="mt-2"
                variant="primary"
              >{{
                $t('improvePrice')
              }}</b-button>
            </div>
          </b-col>
          <b-col
            sm="4"
            class="d-flex flex-row justify-content-end align-items-start px-3 py-2"
          >
            <div class="d-flex flex-column">
              <div class="d-flex flex-row justify-content-end">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder">{{ $t('unitPrice') }}</span>
                  <span
                    class="font-weight-bolder"
                  >S/IVA $ {{ product.unit_price | money }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center mt-1 justify-content-end">
                <div class="d-flex align-items-center mr-1">
                  <h3 class="m-0 font-weight-bolder mr-1 text-light">
                    {{ $t('total') }}:
                  </h3>
                  <span
                    class="font-weight-bolder text-light h3 m-0"
                  >$
                    {{
                      (parseInt(product.quantity) *
                        parseFloat(product.quantity))
                        | money
                    }}</span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    roundStage: {
      type: String,
      default: () => '',
    },
  },

  computed: {
    ...mapGetters('app', ['unitsFilter']),
  },
}
</script>

<style lang="scss" scoped>
.bg-best-price {
  background-color: #77c77b;
}
</style>
