<template>
  <div>
    <b-row>
      <b-col
        sm="3"
      >
        <div class="bg-white text-center py-3 h-100 border rounded shadow mr-2">
          <h2>
            {{ $t('myList') }}
          </h2>
          <h2>({{ products.length }} {{ $t('products') }})</h2>
        </div>
      </b-col>
      <b-col sm="9">
        <b-row
          class="bg-white h-100 text-center align-items-center border rounded shadow"
          align-v="center"
        >
          <b-col class="px-0">
            <div>
              <b-img
                fluid
                width="120px"
                :src="require('@/assets/images/logo/logo.png')"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="roundStage == 'secondRound'"
        sm="2"
        class="px-0"
      >
        <div class="bg-white text-center py-5 h-100 border rounded shadow ml-2">
          <h2>
            {{ $t('bestPrice') }}
          </h2>
        </div>
      </b-col>
    </b-row>
    <div
      v-for="product in products"
      :key="product.id"
    >
      <template v-if="product.is_best_price && $route.name === 'secondRound'">
        <product-row-best-price
          :round-stage="roundStage"
          :product="product"
        />
      </template>
      <template v-else>
        <product-row
          :round-stage="roundStage"
          :product="product"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ProductRow from '@/components/pricing/ProductRow.vue'
import ProductRowBestPrice from '@/components/pricing/ProductRowBestPrice.vue'

export default {
  components: {
    ProductRow,
    ProductRowBestPrice,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    roundStage: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    colSize() {
      if (this.roundStage === 'firstRound') return '9'
      if (this.roundStage === 'secondRound') return '7'
      return ''
    },
  },
}
</script>

<style></style>
